import React from "react";
import PropTypes from "prop-types";
import { safeLowerString } from "src/utils/utils";
import styles from "./FAQBlock.module.scss";

class FAQBlock extends React.Component {
	static propTypes = {
		faqList: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.oneOfType([
					PropTypes.string,
					PropTypes.element
				]).isRequired,
				content: PropTypes.oneOfType([
					PropTypes.string,
					PropTypes.element
				]).isRequired
			})
		).isRequired,
		classes: PropTypes.string,
		doWeToggleAccordion: PropTypes.bool
	};

	static defaultProps = {
		classes: "",
		doWeToggleAccordion: false
	};

	constructor(props) {
		super(props);
		this.state = {
			selectedAccordion: 0
		};
	}

	toggleAccordion(i) {
		this.setState({
			selectedAccordion: i
		});
	}

	render() {
		const faqContents = this.props.faqList.map(faq => {
			const safeTitle = safeLowerString(faq.title);
			return (
				<li>
					<a href={`#${safeTitle}`}>{faq.title}</a>
				</li>
			);
		});

		const renderedStripList = this.props.faqList.map((faq, i) => {
			let isShowing = "";
			if (this.props.doWeToggleAccordion) {
				isShowing = i === this.state.selectedAccordion ? "" : "hidden";
			}

			const safeTitle = safeLowerString(faq.title);

			return (
				<div
					onClick={() => this.toggleAccordion(i)}
					role="button"
					tabIndex={0}
				>
					<h3 className={`${styles.faq_header}`} id={safeTitle}>
						{faq.title}
					</h3>
					<div className={`${isShowing}`}>{faq.content}</div>
				</div>
			);
		});

		return (
			<div
				className={`container maxContainer outerContainer m-b-xl ${this.props.classes}`}
			>
				<div
					className={`is-offset-2 column is-8-desktop is-half-tablet is-full-mobile`}
				>
					<h2 className={`${styles.faq_header}`}>FAQ</h2>
					<ul>{faqContents}</ul>
					{renderedStripList}
				</div>
			</div>
		);
	}
}

export default FAQBlock;
