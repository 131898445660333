import React from "react";
import PropTypes from "prop-types";
import { CONTACT_ME_PAGE } from "src/routesConstants";

import Img from "gatsby-image";
import styles from "./StripPrimaryTextImage.module.scss";

const propTypes = {
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
		.isRequired,
	alignment: PropTypes.string,
	image: PropTypes.shape(),
	video: PropTypes.shape(),
	subTitle: PropTypes.oneOfType([
		PropTypes.element.isRequired,
		PropTypes.string.isRequired
	]).isRequired,
	addDemo: PropTypes.bool,
	classes: PropTypes.string
};

const defaultProps = {
	addDemo: true,
	alignment: "left",
	image: null,
	video: null,
	classes: ""
};

// ${styles.whatIDoBlock
function StripPrimaryTextImage({
	image,
	video,
	subTitle,
	title,
	alignment,
	addDemo,
	classes
}) {
	let demoButton;
	if (addDemo) {
		demoButton = (
			<a role="button" className="button is-link " href={CONTACT_ME_PAGE}>
				Get in touch
			</a>
		);
	}
	let videoImage;
	const alignedClass = alignment === "left" ? "" : styles.alternate;
	const mediaAlign = alignment === "left" ? "forceRightAlignColumn" : "";
	if (image) {
		const imageRatio =
			image.aspectRatio > 1 ? "imageSizeHorizontal" : "imageSizeVertical";

		videoImage = (
			<Img
				fluid={image}
				alt=""
				fadeIn={false}
				className={`${styles[imageRatio]} ${mediaAlign}`}
				imgStyle={{ objectFit: "contain" }}
			/>
		);
	} else if (video) {
		videoImage = (
			<div className={`${mediaAlign} ${styles.imageSizeHorizontal}`}>
				<video
					autoPlay
					muted
					loop
					playsInline
					className={`${styles.videoSize}`}
				>
					<source src={video} type="video/mp4" />
				</video>
			</div>
		);
	}

	return (
		<div
			className={`container maxContainer outerContainer m-b-xl ${classes}`}
		>
			<div
				className={`columns is-vcentered ${alignedClass}`}
				key={`${subTitle}`}
			>
				<div
					className={`column is-half-desktop is-half-tablet is-full-mobile `}
				>
					<h1>{title}</h1>
					{subTitle}
					{demoButton}
				</div>
				<div
					className={`column is-half-desktop is-one-third-tablet is-full-mobile m-b-md `}
				>
					{videoImage}
				</div>
			</div>
		</div>
	);
}

StripPrimaryTextImage.propTypes = propTypes;
StripPrimaryTextImage.defaultProps = defaultProps;

export default StripPrimaryTextImage;
