import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql, Link } from "gatsby";

// Local imports
import Layout from "src/layouts/LayoutWhiteHeader";
import StripPrimary from "src/components/generic/StripPrimary/StripPrimary";
import StripsAlternatingContent from "src/components/generic/StripsAlternatingContent/StripsAlternatingContent";
import SEO from "src/components/generic/SEO/SEO";
import config from "data/SiteConfig";
import StripPrimaryTextImage from "src/components/generic/StripPrimaryTextImage/StripPrimaryTextImage";
import FAQBlock from "src/components/generic/FAQBlock/FAQBlock";
import socialShare from "static/images/social_sharing/ua_migration.png";
import queryVid from "static/images/pages/ua_migration/ga_example_vid.mp4";

// Styles
// import styles from "./index.module.scss";

const gaBackup = {
	title:
		"Google Analytics is going away. Don't lose your data. Back it up now and get ready for GA 4.",
	subTitle: (
		<React.Fragment>
			<p>
				Universal Analytics will stop recording new hits on 1st July
				2023. After approximately 6 months it will stop working
				altogether.
			</p>
			<p>
				We&apos;ll work with you to set you up a data warehouse and
				back-up your GA data so you&apos;ll have all of it forever. All
				the gritty details below. Price from £600.
			</p>
		</React.Fragment>
	),
	image: null
};
const faqList = [
	{
		title: "How does the process for this work?",
		content: (
			<React.Fragment>
				<p>
					1. At the start we&apos;ll run a short consulting session.
					We&apos;ll work out your data requirements and talk you
					through what is possible. Analytics is complicated and
					we&apos;ll walk you through it.
				</p>
				<p>
					2. We&apos;ll get you set-up with a data warehouse (if you
					haven&apos;t got one).
				</p>
				<p>
					3. We&apos;ll back-up all your data into it. Your new UA
					data will be downloaded and stored every day until UA
					sunsets.
				</p>
				<p>
					4. Finally we&apos;ll set you up with our query platform so
					you get the benefits of the data warehouse as well as just
					using it for backup (details below)!
				</p>
			</React.Fragment>
		),
		image: null
	},
	{
		title:
			"Are there any benefits to a data warehouse aside from backing up your data?",
		content: (
			<React.Fragment>
				<p>
					Yes! We&apos;ve got plenty of customers who have been
					storing GA data before they needed to backup. Primarily for
					the following reasons:
				</p>
				<ol>
					<li>
						<strong>Avoiding sampling:</strong> By downloading the
						data day by day, we let you build custom reports without
						sampling.
					</li>
					<li>
						<strong>Speed:</strong> Working with large amounts of
						data is notably faster in a data warehouse than in the
						GA interface.
					</li>
					<li>
						<strong>Joining other data sources:</strong> Having all
						your data in a warehouse makes it easier to join with
						other data types. We focus on the SEO data sources, so
						typically that&apos;s also Search Console, crawl data,
						logs etc.
					</li>
					<li>
						<strong>Fixing and changing data:</strong> Once data is
						exported it is possible to change it, interpolate
						missing data, remove PII etc. You can also categorise
						retroactively.
					</li>
				</ol>
			</React.Fragment>
		),
		image: null
	},
	{
		title: "Can you tie together GA4 and UA?",
		content: (
			<React.Fragment>
				<p>
					The data models aren&apos;t identical so you won&apos;t be
					able to get perfect session to session numbers.
				</p>
				<p>
					But we will be able to help you tie together the data
					sources.
				</p>
				<p>
					We are also building reporting templates to offer to people,
					which should be available by the end of April at latest, but
					hopefully sooner.
				</p>
			</React.Fragment>
		),
		image: null
	},
	{
		title: "How do you work with a data warehouse?",
		content: (
			<React.Fragment>
				<p>
					When any data is in a data warehouse, you need to use SQL to
					work with it.
				</p>
				<p>
					Piped Out provides a query builder with pre-built queries
					that just allows you to fill in a form to generate what you
					need. (Handy gif below shows basic SEO metrics (i.e. landing
					pages report).)
				</p>
				<video
					autoPlay
					muted
					loop
					playsInline
					className={`videoWidth100`}
				>
					<source src={queryVid} type="video/mp4" />
				</video>
				<p>
					You can use this for ad-hoc analysis, or to plug straight
					into a reporting tool like Data Studio, Tableau etc.
				</p>
			</React.Fragment>
		),
		image: null
	},
	{
		title: "How far back can I back-up?",
		content: (
			<React.Fragment>
				<p>
					As far back as you&apos;ve got. However keep in mind that GA
					has a default retention period of 26 months, so unless
					you&apos;ve changed it any data before that becomes
					unuseable.
				</p>
			</React.Fragment>
		),
		image: null
	},
	{
		title: "Are there restrictions on what you can backup?",
		content: (
			<React.Fragment>
				<p>
					Yes. All the back-ups from GA make use of the API and this
					gives some limitations, doesn&apos;t matter if you&apos;re
					using a third part tool or us. Everyone will bump into these
					limitations.
				</p>
				<p>
					The primary one is each report is limited to 7 dimensions
					and 10 metrics. This means you can&apos;t get literally
					everything at once (Unless you&apos;ve already done some
					custom work to store a unique session level ID.).
				</p>
				<p>
					Instead you have to broadly think about it in terms of
					reports you want to generate, we can help you work through
					this.
				</p>
			</React.Fragment>
		),
		image: null
	},
	{
		title: "What data warehouses do you support?",
		content: (
			<React.Fragment>
				<p>
					We primarily work with BigQuery at the moment. We do work
					with other data warehouses, there additional costs for
					working with them.
				</p>
				<p>
					Specifically for the scenario where you have been using UA
					and you&apos;re going to move to GA 4, we&apos;d highly
					recommend using BigQuery because GA 4 has a built in
					BigQuery export.
				</p>
				<p>
					By having both of these in the same place, it sets it up for
					tying the two sources together.
				</p>
			</React.Fragment>
		),
		image: null
	},

	{
		title:
			"What if I want to migrate away from Google Analytics completely?",
		content: (
			<React.Fragment>
				<p>
					If you just want an analytics platform with your GA data,
					then this probably isn&apos;t the best option.
				</p>
				<p>
					Pretty much every other small analytics provider at this
					point will be pushing out a GA import feature as fast as
					possible (maybe not Adobe for example, but certainly people
					like Fathom, Matomo already has one etc.).
				</p>
				<p>
					Wait until the platform of your choice has and they&apos;ll
					almost certainly let you import as part of their sign-up
					process.
				</p>
				<p>
					But if in the long term you want to be able to tie together
					all your different sources more easily then putting it all
					into a data warehouse is a great idea and we can help!
				</p>
			</React.Fragment>
		),
		image: null
	}
];

// https://usefathom.com/pricing

const propTypes = {
	data: PropTypes.shape().isRequired
};

const Index = ({ data }) => {
	// Set images
	gaBackup.image = data.ga_primary.childImageSharp.fluid;

	const title = "Back-up your UA data for migration to GA4";
	const description =
		"Universal Analytics will stop recording new hits on 1st July 2023. After approximately 6 months it will stop working altogether. We&apos;ll work with you to set you up a data warehouse and back-up your GA data so you&apos;ll have all of it forever.";

	const pricingBox = (
		<div className="container">
			<div className="column is-8-desktop is-half-tablet is-full-mobile is-offset-2 m-b-m">
				<h2>Pricing</h2>
				<div className="columns noMargin">
					<div className="column ">
						<p>
							Our pricing for Google Analytics backup is a fixed
							cost typically between £600 & £1,000.
						</p>
					</div>
					<div className="column background-grey">
						<strong>Includes</strong>
						<ul className="tickList">
							<li>Consultation</li>
							<li>
								Set-up of data warehouse (or integration into
								existing).
							</li>
							<li>Loading of data</li>
							<li>Access to query platform</li>
							<li>Access to our training resources</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);

	return (
		<Layout>
			<Helmet title={config.siteTitle} />
			<SEO
				pageTitle={title}
				pageDescription={description}
				pageImage={socialShare}
			/>
			{/* <section>
				<div className={`container maxContainer m-b-xl`}>
					<h1>{stripPrimary.title}</h1>
					<p>{stripPrimary.subTitle}</p>
				</div>
			</section> */}
			<section>
				{/* <StripTitle title="What do we load?" /> */}
				<StripPrimaryTextImage
					title={gaBackup.title}
					subTitle={gaBackup.subTitle}
					image={gaBackup.image}
					classes="m-t-lg"
				/>
				{pricingBox}
				<FAQBlock faqList={faqList} />
			</section>
		</Layout>
	);
};

Index.propTypes = propTypes;

export default Index;

// The graphql query made here will automatically be entered as the
// prop data in data
export const pageQuery = graphql`
	query UAMigration {
		# This gets the main image
		primaryImage: file(
			relativePath: {
				eq: "images/pages/homepage/homepage_stripprimary.jpg"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 935, maxHeight: 575) {
					...GatsbyImageSharpFluid
				}
			}
		}
		# Get us the main alternating images
		alternatingImg0: file(
			relativePath: {
				eq: "images/pages/how_does_it_work/people_small.png"
			}
		) {
			childImageSharp {
				fluid(maxWidth: 501, maxHeight: 393) {
					...GatsbyImageSharpFluid
				}
			}
		}
		# Get us the main alternating images
		ga_primary: file(
			relativePath: { eq: "images/pages/ga_data_backup.png" }
		) {
			childImageSharp {
				fluid(maxWidth: 670, maxHeight: 640) {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`;
